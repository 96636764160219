//is this a valid number

export const checkCardNum = function (cardNum) {
    let s = 0;
    let doubleDigit = false;
    for (let i = cardNum.length - 1; i >= 0; i--) {
        let digit = +cardNum[i];
        if (doubleDigit) {
            digit *= 2;
            if (digit > 9)
                digit -= 9;
        }
        s += digit;
        doubleDigit = !doubleDigit;
    }
    return s % 10 === 0;
}


//figure card type

//American Express: Starting with 34 or 37, length 15 digits.
//Visa: Starting with 4, length 13 or 16 digits.
//MasterCard: Starting with 51 through 55, length 16 digits.
//Discover: Starting with 6011, length 16 digits or starting with 5, length 15 digits.
//Diners Club: Starting with 300 through 305, 36, or 38, length 14 digits.
//JCB: Starting with 2131 or 1800, length 15 digits or starting with 35, length 16 digits.

export const getCardType = function (c) {
    if ((c.substring(0, 2) === '34' || c.substring(0, 2) === '37') && c.length === 15)
        return 'AX'
    if (c.substring(0, 1) === '4' && (c.length === 13 || c.length === 16))
        return 'VA'
    if (c.substring(0, 1) === '5' && c.length === 16)
        return 'MC'
    if ((c.substring(0, 4) === '6011' && c.length === 16) || (c.substring(0, 1) === '5' && c.length === 15))
        return 'DS'
    if (c.substring(0, 1) === '3' && c.length === 14)
        return 'DI'
    if ((c.substring(0, 2) === '35' && c.length === 16) || ((c.substring(0, 4) === '2131' || c.substring(0, 4) === '1800') && c.length === 15))
        return 'JC'
}


//is this a valid expDate

export const isExpDateValid = function (expDate) {
    let today = new Date();
    let month = today.getMonth() + 1; // jan == 0
    let year = today.getFullYear() - 2000; // just the last 2 numbers
    let expMonth = parseInt(expDate.substring(0, 2));
    let expYear = parseInt(expDate.substring(2, 4));

    if (expMonth > 12 || expMonth === 0) //user entered an invalid month
        return false
    else if (expYear > year)
        return true
    else if (expYear < year)
        return false
    else if (expMonth < month)
        return false
    else
        return true
}