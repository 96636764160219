import React from 'react';
// import './App.css';
import NavBar from './NavBar';
import Form from './Form'
import Typography from '@material-ui/core/Typography';
import {MainProvider} from './MainContext';

function App() {
    return (
        <div>
            <NavBar/>
            {/* --- turn off the following 3 lines when the RTC reopens for the season --- */}
            {/*<Typography variant="h6" align="center" style={{marginTop: '10px'}}>*/}
            {/*    The Rossignol Test Center at Deer Valley Resort is closed for the season*/}
            {/*</Typography>*/}

            {/* --- turn on the following 4 lines when the RTC reopens for the season --- */}
            <Typography variant="h6" align="center" style={{marginTop: '10px'}}>
                Welcome to the Rossignol Test Center at Deer Valley Resort
            </Typography>
            <Form/>
        </div>
    );
}
export default App;
