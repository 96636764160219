import React, {createContext, useState} from "react";

export const MainContext = createContext();

export function MainProvider(props){


    const [showLogin, setShowLogin] = useState(true);
    const [auth0Token, setAuth0Token] = useState('');
    const changeShowLogin = (val) =>setShowLogin(val);
    const changeAuth0Token = (token) =>setAuth0Token(token);

    return (
        <MainContext.Provider value={{showLogin, changeShowLogin,auth0Token, changeAuth0Token}}>
            {props.children}
        </MainContext.Provider>
    );

}