const Encrypt = (cardNumber) => {

    let crypto = global.crypto || global.msCrypto;

    if (crypto.subtle) {
        //alert("Cryptography API Supported");
    } else {
        alert("Cryptography API not Supported");
    }
    const data = process.env.REACT_APP_HOSTID + cardNumber;
    const binaryDerString = window.atob(process.env.REACT_APP_KEY);
    const binaryDer = convertStringToArrayBufferView(binaryDerString);
    const bt = convertStringToArrayBufferView(data);

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/false || !!document.documentMode;

    function convertStringToArrayBufferView(str) {
        let bytes = new Uint8Array(str.length);

        for (let iii = 0; iii < str.length; iii++) {
            bytes[iii] = str.charCodeAt(iii);
        }
        return bytes;
    }

    function ab2str(buf) {
        return String.fromCharCode.apply(null, new Uint8Array(buf));
    }


    if (isIE) {
        crypto.subtle.importKey("spki", binaryDer, {
            name: "RSA-OAEP",
            modulusLength: 2048,
            hash: {name: "SHA-1"}
        }, false, ["encrypt"]).oncomplete = function (e) {
            let key = e.target.result;
            try {
                crypto.subtle.encrypt({name: "RSA-OAEP", hash: "SHA-1"}, key, bt).oncomplete = function (e) {
                    let encrypted = e.target.result;
                    window.encrypt=(window.btoa(ab2str(encrypted)));
                };
            } catch (err) {
                alert(err);
            }
        };
    } else              {
        crypto.subtle.importKey("spki", binaryDer, { name: "RSA-OAEP", length: 2048, hash: "SHA-1" }, false, ["encrypt"]).then(function (key) {
            crypto.subtle.encrypt({ name: "RSA-OAEP", hash: "SHA-1" }, key, bt).then(function (encrypted) {
                window.encrypt=(window.btoa(ab2str(encrypted)));
            }).catch(function (err) {alert(err)});
        }).catch(function (err) {alert(err);});
    }

}


export default Encrypt;
