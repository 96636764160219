import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {Grid} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import logo from './assets/RossignolLogo.png';
import DVlogo from './assets/DVLogo.png';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
        },
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
}));

export default function NavBar() {
    const classes = useStyles();

    return (
        <div >
            <AppBar position="static" style={{background: 'white'}}>
                <Toolbar>
                    <Grid spacing={0} container direction="row" justify="space-between" alignItems="center" >
                        <Grid item xs={12} sm={6}>
                            <img src={logo} alt="RossiLogo"/>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.root}>>
                            <img src={DVlogo} alt="DVLogo" />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );
}