import React, {useState, useContext} from 'react';
import axios from "axios";
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';


import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {MainContext} from './MainContext';

import Login from './Login';
import Success from './Success'
import beep from "./assets/beep-07.wav";

import Encrypt from "./helper/encrypt";
import {checkCardNum, getCardType, isExpDateValid} from "./helper/validation";
import Alert from "@material-ui/lab/Alert";
import {CircularProgress} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Select from "@material-ui/core/Select";


const useStyles = makeStyles({
    root: {
        minWidth: 275,
        margin: '20px'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function OutlinedCard() {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;

    const {showLogin, changeShowLogin, auth0Token} = useContext(MainContext);
    const [cardNumber, setCardNumber] = useState('');
    const [initialCardNumber, setInitialCardNumber] = useState('');
    const [cardType, setCardType] = useState('');
    const [last4, setLast4] = useState('');
    const [expDate, setExpDate] = useState('');
    const [cardError, setCardError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [clientID, setClientID] = useState(false);
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState(false);
    const [state, setState] = useState(false);
    const [zip, setZip] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [skierType, setSkierType] = useState('');
    const [soleLength, setSoleLength] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [reservation, setReservation] = useState({"theDay": 'today', "theSlot": '10'});
    const [currentReservation, setCurrentReservation] = useState({"theDay": 'today', "theSlot": '10'});
    const [audioBeep] = useState(new Audio(beep));
    const [agree, setAgree] = useState(false)


    let clientObject = {
        "address": "",
        "birthDate": "",
        "cardType": "",
        "ccNumber": "",
        "city": "",
        "clientID": 0,
        "email": "",
        "expDate": "",
        "fName": "",
        "height": "",
        "last4": "",
        "lName": "",
        "phone": "",
        "skierType": "",
        "soleLength": "",
        "state": "",
        "weight": "",
        "zip": "",
        "reservations": [
            // {
            //     "theDay": '',
            //     "theSlot": ''
            // }
        ]
    };

    const [clientObj, setClientObj] = useState(clientObject);


    const reset = () => {
        setCardNumber('');
        setCardType('');
        setEmail('');
        setSuccess(false);
        setError(false);
        setInputError(false);
        changeShowLogin(true);
        window.encrypt = '';

        // setCardError(false);

        // setExpDate('');
        // setName('')

        // window.encrypt = "";
        // guid = '';
        // pk = 0;
        // setSpinner(false);
    };

    const updateClient = c => {
        setClientID(c.clientID);
        setFname(c.fName);
        setLname(c.lName);
        setAddress(c.address);
        setCity(c.city);
        setState(c.state);
        setZip(c.zip);
        setPhone(c.phone);
        setEmail(c.email);
        setHeight(c.height);
        setWeight(c.weight);
        setSoleLength(c.soleLength);
        setBirthDate(formatBirthDate(c.birthDate));
        setSkierType(c.skierType);
        setCardNumber(c.ccNumber);
        setCardType(c.cardType);
        setLast4(c.last4);
        setExpDate(c.expDate);
        setInitialCardNumber(c.ccNumber);
        setReservation(c.reservations)
    }

    const formatBirthDate = (bdate) => {
        if (bdate.length) {
            let val = bdate.substring(0, 10);
            let year = val.substring(0, 4);
            let month = val.substring(5, 7);
            let day = val.substring(8, 10);
            return month + '/' + day + '/' + year;
        } else
            return '';

    }

    const handleTypeButtonClick = (val) => {
        setSkierType(val);
    };

    const handleCheckbox =() =>{
        setAgree(()=>!agree)
    }

    const handleCardNumber = (e) => {
        setCardNumber(e.target.value);
    };

    const validateCardNumber = (e) => {
        if (!initialCardNumber || initialCardNumber !== cardNumber)  // the cc number has changed
        {
            setCardType('');
            setLast4('');

            window.encrypt = '';
            if (cardNumber.length) {
                if (checkCardNum(cardNumber)) {
                    Encrypt(cardNumber);
                    setCardType(getCardType(cardNumber));
                    setCardError(false);
                    setLast4(cardNumber.substring(12, 16));
                } else {
                    window.encrypt = ''
                    setCardError(true);
                }
            }
        }
    }

    const checkDate = () => {
        setDateError(false);
        if (expDate.length > 0) {
            if (expDate.length === 4 && !isNaN(expDate)) {
                setDateError(!isExpDateValid(expDate));
            } else if (expDate.length === 6 && !isNaN(expDate.substring(0, 1)) && expDate.substring(1, 2) === '/' && !isNaN(expDate.substring(2))) {
                setDateError(!isExpDateValid('0' + expDate.substring(0, 1) + expDate.substring(4, 6)))
            } else if (expDate.length === 7 && !isNaN(expDate.substring(0, 2)) && !isNaN(expDate.substring(3)) && expDate.substring(2, 3) === '/') {
                setDateError(!isExpDateValid(expDate.substring(0, 2) + expDate.substring(5, 7)))
            } else if (expDate.length === 5) {
                if (!isNaN(expDate.substring(0, 2) + expDate.substring(3, 5))) {
                    setDateError(!isExpDateValid(expDate.substring(0, 2) + expDate.substring(3, 5)))
                } else {
                    setDateError(true)
                }
            } else {
                setDateError(true)
            }
        }
    }

    const reduceDate = (expdate) => {
        if (expdate.length === 4)
            return expdate
        else if (expdate.length === 5)
            return expDate.substring(0, 2) + expDate.substring(3, 5)
        else if (expdate.length === 6)
            return '0' + expDate.substring(0, 1) + expDate.substring(4, 6)
        else
            return expDate.substring(0, 2) + expDate.substring(5, 7)
    }

    const sendEmail = async (obj) => {
        let lastRes = obj.reservations.slice(-1)  //the current reservation should always be the last object in the array
        let options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};


        let resDate = new Date(lastRes[0].theDay);
        resDate.setDate(resDate.getDate() + 1);
        // alert(lastRes[0].theDay);
        // alert(resDate);
        try {
            await axios.get(process.env.REACT_APP_SEND_EMAIL_URL, {
                params: {
                    name: obj.fName + ' ' + obj.lName,
                    resdate: resDate.toLocaleDateString("en-US", options),
                    restime: lastRes[0].theSlot,
                    email: obj.email
                }
            })
                .then(res => {

                    //the email should have been sent
                })
                .catch(e => {
                    alert(e);
                });
        } catch (err) {
            alert(err)
            // setSpinner(false);
            // setError(true);
        }


    }

    const checkForInputErrors = () => {
        if (cardError || cardNumber.length === 0 || ((cardNumber != initialCardNumber) && window.encrypt === ''))
            return true;
        else if (dateError || expDate.length === 0)
            return true;
        else if (fname.length === 0)
            return true;
        else if (lname.length === 0)
            return true;
        else if (birthDate.length === 0)
            return true;
        else if (email.length === 0)
            return true;
        else if (skierType.length === 0)
            return true;
        else
            return false
    }


    const postData = async (e) => {
        e.preventDefault();
        setInputError(false);
        if (!checkForInputErrors()) {
            setSpinner(true);
            let theBirthDate = new Date(birthDate).toISOString();
            let ccNumber = '';
            if (window.encrypt)
                ccNumber = window.encrypt
            else
                ccNumber = cardNumber;

            clientObject =
                {
                    "address": address,
                    "birthDate": theBirthDate,
                    "ccNumber": ccNumber,
                    "city": city,
                    "clientID": clientID,
                    "email": email,
                    "expDate": expDate,
                    "fName": fname,
                    "height": height,
                    "lName": lname,
                    "phone": phone,
                    "skierType": skierType,
                    "soleLength": soleLength,
                    "state": state,
                    "weight": weight,
                    "zip": zip,
                    "last4": last4,
                    "cardType": cardType,
                    // "reservations": reservation
                };

            try {

                await axios.post(process.env.REACT_APP_POST_URL, clientObject,
                    {headers: {Authorization: 'Bearer ' + auth0Token}})
                    .then(res => {
                        window.encrypt = '';
                        setSpinner(false);
                        setSuccess(true);
                        // sendEmail(clientObject);  temp turned off until rtc says they want it.  T.S. 12/1/2023

                    })
                    .catch((e) => {
                        // alert(JSON.stringify(clientObject));

                       // alert(e.toString());
                        setSpinner(false);
                        setError(true);
                        audioBeep.play();

                    });
            } catch (err) {
                //alert(err.toString());
                setSpinner(false);
                setError(true);
                audioBeep.play();

            }
        } else {
            setInputError(true);
            audioBeep.play();
        }
    };

    const startOver = () => {
        setClientObj(clientObject);
        changeShowLogin(true);
        setSuccess(false);
        setInputError(false);
        setError(false);
        window.encrypt = '';
    }


    return (
        <>
            {showLogin && <Login client={clientObj} setClient={updateClient} setCurrentReservation={setCurrentReservation}/>}
            {success && <Success reset={startOver} email={email} currentReservation={currentReservation} />}
            {!showLogin && <Card className={classes.root} variant="outlined">
                <CardContent>

                    <Typography variant="h6" gutterBottom>
                        Client Information
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="firstName"
                                name="firstName"
                                label="First name"
                                autoComplete="new-password"
                                value={fname}
                                onChange={(e) => setFname(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                name="lastName"
                                label="Last name"
                                autoComplete="new-password"
                                value={lname}
                                onChange={(e) => setLname(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="address"
                                name="address"
                                label="Address"
                                autoComplete="new-password"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                id="city"
                                name="city"
                                label="City"
                                autoComplete="new-password"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField required fullWidth id="state" name="state" label="State/Province/Region"
                                       autoComplete="new-password"
                                       value={state}
                                       onChange={(e) => setState(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                id="zip"
                                name="zip"
                                label="Zip / Postal code"
                                autoComplete="new-password"
                                value={zip}
                                onChange={(e) => setZip(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="Phone"
                                name="phone"
                                label="Phone #"
                                autoComplete="new-password"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="email"
                                fullWidth
                                name="email"
                                label="Email"
                                autoComplete="new-password"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                fullWidth
                                id="height"
                                name="height"
                                label="Height"
                                autoComplete="off"
                                helperText="inches"
                                value={height}
                                onChange={(e) => setHeight(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField required fullWidth id="weight" name="weight" label="Weight" helperText="pounds" autoComplete="off"
                                       value={weight} onChange={(e) => setWeight(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                fullWidth
                                id="sole"
                                name="sol"
                                label="Sole Length"
                                autoComplete="off"
                                helperText="millimeters"
                                value={soleLength}
                                onChange={(e) => setSoleLength(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField required fullWidth id="birthDate" name="birthDate" label="Birth Date" autoComplete="off"
                                       value={birthDate} helperText="mm/dd/yyyy"
                                       onChange={(e) => setBirthDate(e.target.value)}/>
                        </Grid>


                        <Grid item xs={12} sm={3}>
                            <Typography variant="h6" align="center" gutterBottom>
                                Skier Type
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Button variant="contained" color={skierType === 'Type1' ? "primary" : ""} fullWidth
                                    onClick={() => handleTypeButtonClick('Type1')}>Type 1</Button>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <Button variant="contained" color={skierType === 'Type2' ? "primary" : ""} fullWidth
                                    onClick={() => handleTypeButtonClick('Type2')}>Type 2</Button>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Button variant="contained" color={skierType === 'Type3' ? "primary" : ""} fullWidth
                                    onClick={() => handleTypeButtonClick('Type3')}>Type 3</Button>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                label="Card Number"
                                id="cardnumber"
                                autoComplete="off"
                                error={cardError}
                                helperText={cardError ? "Invalid Number" : null}
                                value={cardNumber}
                                onChange={handleCardNumber}
                                onBlur={validateCardNumber}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField required
                                       fullWidth
                                       label="Exp Date"
                                       id="expDate"
                                       autoComplete="off"
                                       error={dateError}
                                       helperText={dateError ? "Invalid Date (mm/yy, mmyy or mm/yyyy)" : "mmyy"}
                                       value={expDate}
                                       onChange={(e) => setExpDate(e.target.value)}
                                       onBlur={(e) => setExpDate(reduceDate(e.target.value))}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>

                        </Grid>


                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid container align="center">
                        {error && <Alert severity="error" style={{width: '100%'}}>
                            <strong>A processing error has occurred.</strong> - Are you sure all of your information is
                            correct?</Alert>}
                        {inputError &&
                        <Alert severity="error" style={{width: '100%'}}>Please make sure that all required fields have a
                            value.</Alert>}
                        <p></p>

                        <Grid item container justify="center">
                            <Grid item>
                                {spinner && <CircularProgress/>}
                            </Grid>
                        </Grid>

                        <Grid item container justify="center">
                            <Grid item>
                                <FormControlLabel
                                    style={{display:'table'}}
                                    control={<div style={{display:'table-cell'}}><Checkbox color="primary" checked={agree} onChange={handleCheckbox} /></div>}
                                    label="By submitting this form you consent to your personal information being shared with Rossignol."
                                />
                                <p>Click <a href={' https://www.rossignol.com/us/privacy-policy'}  target="_blank">here</a> to review Rossignol's privacy policy.</p>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Button disabled={!agree} style={{maxHeight: '50px', minHeight: '70px'}} fullWidth variant="contained"
                                    color="primary" onClick={postData}>Submit</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                        </Grid>
                        <Grid item xs={12}>
                            <Button  style={{maxHeight: '50px', minHeight: '70px'}} fullWidth variant="contained"
                                    color="secondary" onClick={startOver}>Cancel</Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>}
        </>
    );
}