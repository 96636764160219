import React, {useContext, useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Grid, Typography, Button, TextField, CircularProgress} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import {MainContext} from './MainContext';
import axios from "axios";
import logo from './assets/DVLogo.png'
import Alert from '@material-ui/lab/Alert';
// import failBeep from "./assets/beep-10.wav";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormLabel from '@material-ui/core/FormLabel';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import failBeep from "./assets/beep-10.wav";
import beep from "./assets/beep-07.wav";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function AlertDialogSlide({client, setClient, setCurrentReservation}) {


    const {changeShowLogin, auth0Token, changeAuth0Token} = useContext(MainContext);
    const [email, setEmail] = useState('');
    const [birthDay, setBirthday] = useState('');
    const [error, setError] = useState(false);
    const [audioBeep] = useState(new Audio(beep));
    // const [audioSuccess] = useState(new Audio(successBeep));
    const [loading, setLoading] = useState(false);
    const [returning, setReturning] = useState('false');
    const [day, setDay] = useState('Today');
    const [period, setPeriod] = useState('');
    const [timeSlots, setTimeSlots] = useState([{}]);
    const [periodError, setPeriodError] = useState(false);
    const [selectedDay, setSelectedDay] = useState();
    const [dateSelected, setDateSelected] = useState(new Date());

    let todayString = '';
    let tomorrowString = '';
    let today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    todayString = today.toLocaleDateString('en-US', {day: '2-digit'});
    tomorrowString = tomorrow.toLocaleDateString('en-US', {day: '2-digit'});


    const handleChange = (event) => {
        setReturning(event.target.value);
    };

    const handleSubmit = (e) => {
        setPeriodError(false);
        setPeriod((prev) => '10')
        setDateSelected((prev) => today)
        // if (!period) {
        //     setPeriodError(true);
        //     audioBeep.play();
        // } else {
        if (returning === 'true')
            validateUser();
        else {
            // alert(dateSelected.toLocaleDateString("sv"));
            // alert(period);

            client.reservations.push({
                "theDay": dateSelected.toLocaleDateString("sv"),
                "theSlot": period
            });

            setClient(client);  //this should be empty
            changeShowLogin(false);

            setCurrentReservation({"theDay": dateSelected.toLocaleDateString("sv"), "theSlot": period})
        }
        // }
    };

    const handleDayChange = (event) => {
        setDay((event.target.value) || '');
        setPeriod('');
        if (event.target.value === 'Today') {
            setSelectedDay(todayString);
            setDateSelected(today);
        } else if (event.target.value === 'Tomorrow') {
            setSelectedDay(tomorrowString);
            setDateSelected(tomorrow);
        }

    };

    const handlePeriodChange = (event) => {
        setPeriodError(false);
        setPeriod((event.target.value) || '');
    };

    const handleBirthDayChange = (event) => {
        let dateStr = event.target.value || ''
        let newDateStr = dateStr
        if (!dateStr.includes('/') && !dateStr.includes('-') && dateStr.length === 8)
            newDateStr = dateStr.substring(0, 2) + '/' + dateStr.substring(2, 4) + '/' + dateStr.substring(4,8)
        setBirthday(newDateStr);
    };

    const handleEmailChange = (event) => {
        setEmail((event.target.value) || '');
    };


    const getAuth0Token = async () => {
        // setFetchError(false);
        // setSpinner(true);
        let response = await axios.post(
            "https://apidv.us.auth0.com/oauth/token",
            {
                "client_id": process.env.REACT_APP_AUTH0APPKEY,
                "client_secret": process.env.REACT_APP_AUTH0APPSECRET,
                "audience": "https://api.deervalley.com/TokenApi/",
                "grant_type": "client_credentials"
            },
            {headers: {"content-type": "application/json"}}
        ).then((response) => {
            changeAuth0Token(response.data.access_token);
            getAvailableTimeSlots(response.data.access_token);
            // audioBeep.play();
            //alert(response.data.access_token);
            // postData(response.data.access_token);
        }).catch((error) => {
            // audioBeep.play();
            // alert(error);
            //
            // setSpinner(false);
            // setFetchError(true);
        });
    }


    const getAvailableTimeSlots = async (theToken) => {
        try {
            await axios.get(process.env.REACT_APP_GET_TIMESLOTS_URL,
                {headers: {Authorization: 'Bearer ' + theToken}})
                .then((response) => {
                    setTimeSlots(response.data);
                    setDateSelected(today);
                    // alert(JSON.stringify(response.data));
                    // alert(today);
                    // audioSuccess.play();
                    // setSelectedDay(todayString);
                }).catch((error) => {
                    // audioFail.play();
                    // alert(error);
                });
        } catch (err) {
            setLoading(false);
            setError(true);
            // audioFail.play()
        }
    }

    // get the auth0 token when the page loads
    useEffect(() => {
        getAuth0Token();
        setSelectedDay(todayString);
        setDateSelected(today);

    }, []);


    const validateUser = async () => {
        setLoading(true);
        setError(false);

        try {
            await axios.get(process.env.REACT_APP_GET_LOGIN_URL + '?email=' + email + '&birthday=' + birthDay,
                {headers: {Authorization: 'Bearer ' + auth0Token}})
                .then(res => {
                    if (typeof res.data.lName !== 'undefined' && res.data.lName !== '') {

                        // alert(JSON.stringify(res.data.reservations))

                        // res.data.reservations.push({
                        //     "theDay": dateSelected.toLocaleDateString("sv"),
                        //     "theSlot": period
                        // });

                        setClient(res.data);
                        // setCurrentReservation({
                        //     "theDay": dateSelected.toLocaleDateString("sv"),
                        //     "theSlot": period
                        // });
                        changeShowLogin(false);


                    } else {
                        setError(true);
                        audioBeep.play()
                    }

                    // reset();
                    setLoading(false)
                })
                .catch((e) => {
                    setLoading(false);
                    setError(true);
                    audioBeep.play()
                });
        } catch (err) {
            setLoading(false);
            setError(true);
            audioBeep.play()
        }
    }


    return (
        <div>
            <Dialog
                open={true}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <Grid item container justify="center">
                    <Grid item>
                        <img src={logo} className="App-logo" alt="logo"/>
                    </Grid>
                </Grid>
                <DialogTitle id="alert-dialog-slide-title"
                             style={{color: '#388e3c'}}>{"Rossignol Test Center"} </DialogTitle>
                <DialogContent>
                    {returning === 'false' &&
                        <DialogContentText id="alert-dialog-slide-description" color='secondary'>
                            {/*Select from available time slots:*/}
                        </DialogContentText>}
                    {/*<Grid item container alignItems="flex-start">*/}
                    {/*    <Grid item style={{minWidth: "50px"}}>*/}
                    {/*        <FormControl>*/}
                    {/*            <InputLabel htmlFor="demo-dialog-native">Day</InputLabel>*/}
                    {/*            <Select*/}
                    {/*                native*/}
                    {/*                value={day}*/}
                    {/*                onChange={handleDayChange}*/}
                    {/*                input={<Input id="daySelect"/>}*/}
                    {/*            >*/}
                    {/*                <option aria-label="None" value=""/>*/}
                    {/*                <option value={'Today'}>Today</option>*/}
                    {/*                <option value={'Tomorrow'}>Tomorrow</option>*/}
                    {/*            </Select>*/}
                    {/*        </FormControl>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item style={{minWidth: "25px"}}>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item style={{minWidth: "50px"}}>*/}
                    {/*        <FormControl>*/}
                    {/*            <InputLabel htmlFor="demo-dialog-native" style={{minWidth: "100px"}}>Pickup*/}
                    {/*                Time</InputLabel>*/}
                    {/*            <Select*/}
                    {/*                style={{minWidth: "115px"}}*/}
                    {/*                native*/}
                    {/*                value={period}*/}
                    {/*                onChange={handlePeriodChange}*/}
                    {/*                input={<Input id="timeSelect"/>}*/}
                    {/*            >*/}
                    {/*                <option aria-label="None" value=""/>*/}
                    {/*                {timeSlots.filter(obj => typeof obj.theDay !== 'undefined' && obj.theNumber > 0 ? obj.theDay.substring(8, 10) === selectedDay : null).map(obj =>*/}
                    {/*                    <option value={obj.theSlot}>{obj.theSlot}</option>)}*/}
                    {/*            </Select>*/}
                    {/*        </FormControl>*/}
                    {/*    </Grid>*/}

                    {/*</Grid>*/}
                    <DialogContentText id="alert-dialog-slide-description" color='secondary'>
                        Have you been to this test center before?
                    </DialogContentText>


                    <FormControl component="fieldset">
                        <RadioGroup aria-label="returning" name="returning" value={returning} onChange={handleChange}>
                            <FormControlLabel value='false' control={<Radio/>} label="First Time"/>
                            <FormControlLabel value='true' control={<Radio/>} label="Returning"/>
                        </RadioGroup>
                    </FormControl>

                    <Grid item container justify="left">
                        <Grid item xs={12}>
                            {returning === 'true' &&
                                <TextField id="email" name="email" label="Email" autoComplete="off" fullWidth
                                           onChange={handleEmailChange}/>}
                        </Grid>
                        <Grid item xs={7}>
                            {returning === 'true' &&
                                <TextField id="birthDate" name="birthDate" autoComplete="off" label="Birth Date"
                                           helperText="mm/dd/yyyy"
                                           onChange={handleBirthDayChange}/>}
                        </Grid>
                    </Grid>
                    {/*{periodError && <Alert severity="error">Please Select a Pickup Time.</Alert>}*/}
                    {error && <Alert severity="error">Invalid Log-in. User not found.</Alert>}
                    <p></p>

                    <Grid item container justify="center">
                        <Grid item>
                            {loading && <CircularProgress/>}
                        </Grid>
                    </Grid>
                    <Grid item container justify="center">
                        <Grid item>
                            <Button variant="contained" color='primary' onClick={handleSubmit}>Submit</Button>
                        </Grid>
                    </Grid>
                    <p></p>
                </DialogContent>
            </Dialog>
        </div>
    );
}