import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import {Grid} from "@material-ui/core";
// import logo from "./assets/Fred.png";
import success from "./assets/gosign.png";
import successBeep from "./assets/beep-07.wav";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide( {reset, email, currentReservation}) {

    const [audioSuccess] = useState(new Audio(successBeep));
    let theDay = currentReservation.theDay.substring(5,7)+'/'+currentReservation.theDay.substring(8,10)+'/'+currentReservation.theDay.substring(0,4);
    currentReservation.theDay = theDay;

    audioSuccess.play();

    return (
        <div>
            <Dialog
                open={true}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogTitle id="alert-dialog-slide-title" style={{color: '#388e3c'}}>{"Success"}</DialogTitle>
                <DialogContent>

                    <Grid item container justify="center">
                        <Grid item>
                            <img src={success} alt="success logo" />
                        </Grid>
                    </Grid>
                    <p></p>
                    <DialogContentText id="alert-dialog-slide-description">
                        Your request was successfully submitted.
                    </DialogContentText>

                    {/*<DialogContentText id="alert-dialog-slide-description" style={{color: '#388e3c',textAlign:'center'}}>*/}
                    {/*    <strong> Date: {currentReservation.theDay} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; Time: {currentReservation.theSlot}</strong>*/}
                    {/*</DialogContentText>*/}
                    {/*<DialogContentText id="alert-dialog-slide-description" style={{textAlign:'center'}}>*/}
                    {/*    A confirmation email was sent to:*/}
                    {/*</DialogContentText>*/}
                    {/*<DialogContentText id="alert-dialog-slide-description" style={{color: '#388e3c',textAlign:'center'}}>*/}
                    {/*    <strong>{email}</strong>*/}
                    {/*</DialogContentText>*/}

                    <DialogContentText id="alert-dialog-slide-description">
                        Click GO TO LOGIN to return to Login Screen
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={reset} color="primary">
                        GO to Login
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}